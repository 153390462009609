@tailwind base;
@tailwind components;
@tailwind utilities;


.arrow-stepper-start:after {
    border-left: 2rem solid;
    border-top: 2rem solid transparent;
    border-bottom: 2rem solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -2rem;
    z-index: 2;
}

.arrow-stepper-end:before {
    border-left: 2rem solid;
    border-top: 2rem solid transparent;
    border-bottom: 2rem solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    left: 0;
}

.arrow-stepper-center:before {
    border-left: 2rem solid;
    border-top: 2rem solid transparent;
    border-bottom: 2rem solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    left: 0;
}

.arrow-stepper-center:after {
    border-left: 2rem solid;
    border-top: 2rem solid transparent;
    border-bottom: 2rem solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -2rem;
    z-index: 2;

}


i.icon {
    background-position: center;
    background-size: cover;
    display: block;
}


i.icon.icon-edit {
    background-image: url(/public/icons/edit.svg);
}

i.icon.icon-detail {
    background-image: url(/public/icons/detail.svg);

}

i.icon.icon-copy {
    background-image: url(/public/icons/copy.svg);
}

i.icon.icon-add {
    background-image: url(/public/icons/add-dark.svg);
}

i.icon.icon-history {
    background-image: url(/public/icons/history-dark.svg);
}

i.icon.icon-logout {
    background-image: url(/public/icons/logout.svg);
}


i.icon.icon-upload {
    background-image: url(/public/icons/upload.svg);
}

i.icon.icon-download {
    background-image: url(/public/icons/download.svg);
}

i.icon.icon-invoice {
    background-image: url(/public/icons/invoice-dark.svg);
}

::-webkit-scrollbar {
    width: 0;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    color: #E7E8EF;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #E7E8EF;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-no-wrap{
    text-wrap: nowrap;
}

.min-w-\[200px\]{
    min-width: unset;
}